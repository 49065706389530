<template>
    <div :class="['home-warp',isClick?'is-click':'']">
        <div class="exit" @click="exitLogin" v-if="isExite">退出登录</div>
        <div class="rules" @click="lookRules">查看规则</div>
        <div class="my-invitation">
            <div class="invitation-left">
                <img class="teamwork-title" src="../assets/image/pc_teamwork_title.png" />
                <div class="share-title">好友成功充值并消费 即可享受佣金分成</div>
                <div class="home-share-link">
                    <div class="share-left">
                        <div class="scroll-title">最新推荐返佣用户</div>
                        <div class="scroll-header">
                            <div>姓名</div>
                            <div>电话</div>
                            <div>预计分佣</div>
                            <div>参与时间</div>
                        </div>
                        <vue-seamless-scroll :data="scrollList" class="seamless-warp" :class-option="classOption">
                            <ul class="scroll-item">
                                <li v-for="(item,index) in scrollList" :key="index">
                                    <span>{{item.name}}</span>
                                    <span>{{item.mobile}}</span>
                                    <span>{{item.money}}</span>
                                    <span>{{item.time}}</span>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                        <div class="step-list">
                            <div class="step-item">
                                <img src="../assets/image/pc_step_two.png" />
                                <div>复制链接分享好友</div>
                            </div>
                            <img class="step-icon" src="../assets/image/pc_step_icon.png" />
                            <div class="step-item">
                                <img src="../assets/image/pc_step_three.png" />
                                <div>好友充值平台消费</div>
                            </div>
                            <img class="step-icon" src="../assets/image/pc_step_icon.png" />
                            <div class="step-item">
                                <img src="../assets/image/pc_step_four.png" />
                                <div>享受10%佣金分成 </div>
                            </div>
                        </div>
                        <div class="share-footer">
                            <div class="home-share-title">邀请链接：</div>
                            <div class="rules-link-item">
                                <div class="home-share-content">{{hotUrl}}</div>
                                <div class="copy copy-link" :data-clipboard-text="hotUrl">复制</div>
                            </div>
                        </div>
                       
                    </div>
                   
                    
                </div>
            </div>
            <div class="my-invitation-content">
                <img class="teamwork-header" src="../assets/image/pc_teamwork_header.png" />
                <div class="my-invitation-header">我的邀请</div>
                <div class="my-invitation-list">
                    <ul>
                        <li>
                            <span>邀请获客</span>
                            <span>{{recordInfo.invite_apply_submit_count}}</span>
                        </li>
                        <li>
                            <span>待返佣金</span>
                            <span>￥{{recordInfo.wait_money}}</span>
                        </li>
                        <li>
                            <span>可提现佣金</span>
                            <span>￥{{recordInfo.withdraw_money}}</span>
                        </li>
                    </ul>
                    <div class="look" @click="lookDetail">查看明细</div>
                    <div class="code-img">
                        <div class="code-qr">
                            <vue-qr 
                                ref="Qrcode" 
                                :text="hotUrl" 
                                :margin="6" 
                                colorLight="#fff" 
                                :size="120"
                            ></vue-qr>
                        </div>
                        <div
                            class="download"
                            @click="downloadImg" 
                        >保存二维码</div>
                    </div>
                    
                </div>
                
            </div>
            <div class="share-msg">
                <div class="share-msg-content">
                    <img :src="shareImg" />
                    <p>专为新老客户量身定制的推荐返佣平台，只需推荐好友使用沃创云平台，该好友开通外呼系统、优选商机等产品，您即可获得丰厚佣金；推荐人数无上限，分佣总额无上限，详情请点击右上角“查看规则”。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Clipboard from 'clipboard';
    import { validaMobile } from '@/utils/valida'
    import { getHotUrl } from '@/utils/common'
    import { systemType } from '@/utils/enum'
    import { setStorage, removeStroage } from '@/utils/common'
    import vueQr from 'vue-qr'
    import mixins from '@/mixins/downloadImag'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        mixins:  [mixins],
        data() {
            return {
                hotUrl: '',
                isClick: false,
                shareImg: require('@/assets/image/share-msg-title.png'),
                scrollList: [
                    {
                        name: '李**',
                        mobile: '158**316',
                        money: '1630.00元',
                        time: '1天前'
                    },
                    {
                        name: '张**',
                        mobile: '139**213',
                        money: '830.69元',
                        time: '2天前'
                    },
                    {
                        name: '陈**',
                        mobile: '159**323',
                        money: '1536.56元',
                        time: '1天前'
                    },
                    {
                        name: '李*',
                        mobile: '137**239',
                        money: '932.61元',
                        time: '3天前'
                    },
                    {
                        name: '吴**',
                        mobile: '139**132',
                        money: '760.00元',
                        time: '1天前'
                    },
                    {
                        name: '叶**',
                        mobile: '158**210',
                        money: '1832.36元',
                        time: '5天前'
                    },
                    {
                        name: '叶**',
                        mobile: '157**399',
                        money: '1322.00元',
                        time: '2天前'
                    },
                    {
                        name: '黄*',
                        mobile: '172**256',
                        money: '822.63元',
                        time: '3天前'
                    },
                    {
                        name: '钟**',
                        mobile: '159**329',
                        money: '1323.03元',
                        time: '3天前'
                    },
                    {
                        name: '周**',
                        mobile: '159**613',
                        money: '310.00元',
                        time: '3天前'
                    },
                    {
                        name: '王**',
                        mobile: '158**823',
                        money: '520.03元',
                        time: '3天前'
                    },
                    {
                        name: '李**',
                        mobile: '136**316',
                        money: '1230.16元',
                        time: '1天前'
                    },
                    {
                        name: '朱**',
                        mobile: '178**219',
                        money: '337.00元',
                        time: '2天前'
                    },
                    {
                        name: '何**',
                        mobile: '188**331',
                        money: '1032.00元',
                        time: '2天前'
                    },
                ]
                
            }
        },
        computed: {
            ...mapState([ 'recordInfo', 'userInfo', 'isExite' ]),
            classOption () {
                return {
                    step: 0.2,
                    limitMoveNum: this.scrollList.length,
                    hoverStop: false,
                }
            }
        },
        components: {
            vueQr,
            vueSeamlessScroll
        },
        async created() {
            const { userId, system_type, token } = this.$route.query
            if(validaMobile()) {
                //移动端
                const query = token ? { token } : { userId, system_type }
                this.$router.replace({ path: '/mobileHome', query })
                return
            }
            if(token) {
                this.hotUrl = `${getHotUrl(this.$route)}/teamwork?token=${token}`
                removeStroage('userId')
                removeStroage('systemType')
                setStorage('token', token)
                this.$store.dispatch('getMyRecord')
                return
            }
            if(!userId) {
                this.$errorPrompt('用户id获取失败')
                return
            }
            if(!systemType.includes(system_type)){
                this.$errorPrompt('system_type类型错误')
                return
            }
            this.hotUrl = `${getHotUrl(this.$route)}/teamwork?userId=${userId || 0}&system_type=${system_type}`
            localStorage.clear()
            setStorage('userId', userId)
            setStorage('systemType', system_type)
            const result = await this.$store.dispatch('getToken', { userId })
            if(!result){
                this.isClick = true
            }
        },
        mounted() {
            const clipboard = new Clipboard('.copy-link');
            clipboard.on('success', ()=> {
                this.$successPrompt('复制成功')
            });
        },
        methods: {
            lookRules() {
                this.$router.push('Rules')
            },
            lookDetail() {
                this.$router.push('detail')
            },
            // 下载二维码图片
            downloadImg () {
                const Qrcode = this.$refs.Qrcode
                this.downloadFile('分享二维码', Qrcode.$el.currentSrc)
            },
            // 退出登录
            exitLogin() {
                this.$confirm('确定退出登录吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$postHttp(this.$api.postExitLogin).then(res => {
                        if(res.code == 1) {
                            this.$successPrompt('退出成功')
                            localStorage.clear()
                            setTimeout(() => {
                                this.$router.replace('/')
                            }, 500);
                        }
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.home-warp{
    min-height: 100%;
    background: url('../assets/image/main_bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    min-width: 1200px;
    padding-bottom: 14px;
    &.is-click{
        pointer-events: none;
    }
    .exit{
        position: absolute;
        left: 0;
        top: 50px;
        background: #FFFFFF;
        opacity: 0.8;
        border-radius: 0 30px 30px 0;
        cursor: pointer;
        @include font(20px,#F53C3C,center);
        @include wh(120px,44px);
        line-height: 44px;
        z-index: 5;
    }
    .rules{
        position: absolute;
        right: 0;
        top: 50px;
        @include font(20px,#fff,center);
        @include wh(120px,44px);
        line-height: 44px;
        box-shadow: 0px 10px 20px rgba(188, 48, 48, 0.5);
        background: #F8AC29;
        border-radius: 30px 0px 0px 30px;
        cursor: pointer;
        z-index: 5;
    }
    .my-invitation{
        position: relative;
        @include flex(flex-end,flex-end);
        flex-wrap: wrap;
        width: 1146px;
        margin: auto;
        margin-top: 134px;
        .invitation-left{
            width: 717px;
            padding-top: 256px;
            position: relative;
            margin-right: 10px;
            .teamwork-title{
                position: absolute;
                right: -20px;
                top: 0;
                @include wh(717px,255px);
                z-index: 2;
            }
            .share-title{
                @include wh(398px,42px);
                @include font(16px,#fff,center);
                line-height: 42px;
                border-radius: 23px;
                background: #D12724;
                margin: auto;
            }
            .home-share-link{
                @include wh(668px,392px);
                margin-top: 18px;
                margin-left: auto;
                background: url('../assets/image/home_share_min.png') no-repeat;
                background-size: 100% 100%;
                overflow: hidden;
                padding: 0 32px 24px 32px;
                .share-left{
                    .scroll-title{
                        padding-top: 20px;
                        @include font(14px,#fff,center);
                        margin-bottom: 8px;
                    }
                    .scroll-header{
                        @include wh(100%,30px);
                        @include flex();
                        background: rgba(255, 255, 255, 0.8);
                        @include font(12px,#F53C3C,center);
                        >div{
                            width: 25%;
                        }
                    }
                    .seamless-warp {
                        height: 120px;
                        overflow: hidden;
                        .scroll-item{
                            li{
                                height: 30px;
                                @include flex();
                                border-bottom: 1px solid rgba(255, 255, 255, 1);
                                @include font(12px,rgba(255, 255, 255, 1),center);
                                span{
                                    width: 25%;
                                    @include overflow(1);
                                }
                            }
                        }
                    }
                    .step-list{
                        @include flex(center,space-between);
                        padding: 32px 70px 21px 67px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        .step-item{
                            img{
                                display: block;
                                margin: auto;
                                @include wh(54px,54px);
                                margin-bottom: 8px;
                            }
                            div{
                                @include font(14px,#fff,center);
                            }
                        }
                        .step-icon{
                            @include wh(60px,12px);
                            margin-bottom: 29px;
                        }
                    }
                    .share-footer{
                        @include flex();
                        margin-top: 18px;
                        .home-share-title{
                            @include font(14px,#fff);
                            opacity: 0.5;
                        }
                        .rules-link-item{
                            flex: 1;
                            @include flex(center,space-between);
                            .home-share-content{
                                @include font(14px,#fff);
                                @include overflow(1);
                                width: 340px;
                            }
                            .copy{
                                @include flex();
                                @include wh(56px,24px);
                                border-radius: 20px;
                                @include font(12px,#fff);
                                line-height: 20px;
                                border: 1px solid rgba(255, 255, 255, 0.5);
                                cursor: pointer;
                                margin-left: 43px;
                            }
                        }
                    }
                }
            }
        }
        .my-invitation-content{
            .teamwork-header{
                display: block;
                @include wh(410px,245px);
            }
            .my-invitation-header{
                margin: auto;
                width: 405px;
                height: 50px;
                line-height: 50px;
                background: #FF8788;
                border-radius: 12px;
                @include font(20px,#fff,center);
            }
            .my-invitation-list{
                margin: auto;
                padding: 0 40px 20px 40px;
                width: 355px;
                overflow: hidden;
                background: #fff;
                border-radius: 0 0 12px 12px;
                box-shadow: 0px 10px 20px rgba(151, 0, 0, 0.16);
                ul{
                    padding: 0;
                    margin-top: 32px;
                    li{
                        @include flex(center,center);
                        margin-bottom: 12px;
                        &:last-child{
                            margin-bottom: 33px;
                        }
                        span{
                            @include font(18px,$main_font_color,center);
                            &:last-child{
                                flex: 1;
                                @include font(18px,$main_font_color,right);
                                font-weight: bold;
                            }
                        }
                    }
                }
                .code-img{
                    margin-top: 24px;
                    text-align: center;
                    font-size: 0;
                    .code-qr{
                        @include wh(120px,120px);
                        box-shadow: 0px 5px 10px rgba(180, 76, 0, 0.3);
                        margin: auto;
                    }
                    .download{
                        @include flex();
                        @include wh(92px,24px);
                        border-radius: 29px;
                        @include font(12px,#FF962B);
                        border: 1px solid #FF962B;
                        cursor: pointer;
                        margin: auto;
                        margin-top: 12px;
                    }
                }
                .look{
                    margin: auto;
                    @include wh(240px,42px);
                    line-height: 42px;
                    @include font(18px,#fff,center);
                    background: -webkit-gradient(linear, left top, left bottom, from(#FFD13B), to(#FF8226));
                    background: -o-linear-gradient(top, #FFD13B 0%, #FF8226 100%);
                    background: linear-gradient(180deg, #FFD13B 0%, #FF8226 100%);
                    -webkit-box-shadow: 0px 3px 6px rgba(255, 111, 0, 0.8);
                            box-shadow: 0px 3px 6px rgba(255, 111, 0, 0.8);
                    box-shadow: 0px 10px 20px rgba(48, 67, 188, 0.1);
                    border-radius: 29px;
                    cursor: pointer;
                }
            }
        }
        .share-msg{
            @include wh(1100px,86px);
            margin-top: 20px;
            margin-left: auto;
            border-radius: 4px;
            background: #FEE4DF;
            padding: 8px;
            .share-msg-content{
                border-radius: 4px;
                background: #fff;
                height: 100%;
                padding: 0 26px 0 41px;
                border: 1px dashed rgba(251, 123, 96, 0.5);
                @include flex();
                img{
                    @include wh(123px,36px);
                    margin-right: 73px;
                }
                p{
                    @include font(16px,rgba(37, 38, 49, 0.8));
                    line-height: 24px;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        left: -41px;
                        top: 10px;
                        @include wh(3px,29px);
                        background: #FC8465;
                        border-radius: 4px;
                    }
                }
            }
        }
        
    }
}
</style>